export const ivcPros = [
  'VITAL-CORE',
  'VITAL-CORE-BENCH-HR',
];

export const ivcRRPros = [
  'IVC-RR-CLINICAL-STUDY',
];

export const vcDataStatusMessageMap = {
  'E-DQM-021': 'Please stay still',
  'E-DQM-022': 'Improve your lighting so your face is evenly lit',
  'E-FDM-041': 'Please keep face in guide',
  'E-FDM-042': 'Please keep face in guide',
  'W-RES-073': 'Please keep face in guide',
  'W-RES-076': 'Please keep face in guide',
  'W-RES-078': 'Please keep face in guide',
  'W-RES-079': 'Face too far from camera',
  'W-RES-080': 'Face too close to camera',
};

// the C codes should be ignored for the current version of core, as well as the W
// messages but only during frame analysis (we react to W messages when included in
// the dataStatus message returned from endSession)
const vcCodesToIgnoreDuringSession = [
  'C-DQM-023',
  'C-DQM-024',
  'W-HRT-062',
];

export const vcProcessDataStatus = (dataStatus, endOfSession = false) => {
  const dataStatusMessages = dataStatus.split(',');
  let shouldRestart = false;
  let shouldReload = false;
  let shouldShowWarnings = false;
  let restartDataStatus = '';
  const warningDataStatuses = [];
  let action = '';
  let data;

  dataStatusMessages.forEach((dataStatusMessage) => {
    if (vcCodesToIgnoreDuringSession.includes(dataStatusMessage) && !endOfSession) return;
    if (dataStatusMessage[0] === 'C') {
      shouldReload = true;
      restartDataStatus = dataStatusMessage;
    } else if (dataStatusMessage === 'W-HRT-062' && endOfSession) {
      shouldRestart = true;
      restartDataStatus = dataStatusMessage;
    } else if (dataStatusMessage[0] === 'E' || dataStatusMessage[0] === 'W') {
      shouldShowWarnings = true;
      warningDataStatuses.push(dataStatusMessage);
    }
  });

  if (shouldRestart) {
    action = 'restart';
    data = restartDataStatus;
  } else if (shouldReload) {
    action = 'reload';
    data = restartDataStatus;
  } else if (shouldShowWarnings) {
    action = 'warning';
    data = warningDataStatuses;
  } else {
    action = 'continue';
  }

  return { action, data };
};
