import { forEach } from 'lodash';
import queryString from 'query-string';

import notifications from '../notification-pages';
import config from '../config';

import { createVitalsRunnerWebWorker } from '../lib/vitals-runner-rr';

const tracks = {};

notifications.forEach((n) => {
  tracks[`notification-${n.type}`] = {
    pages: n.components.map((c, idx) => ({
      component: c,
      noauth: true,
      path: `/notifications/${n.type}-${idx + 1}`,
      isPro: true,
      ...n.additionalComponentProps,
    })),
    returnPath: `/notification-success/${n.type}`,
  };
});

export default tracks;

let remainingRailroadTracks = [];
let initialRoadroadTracks = [];

function getPath(router) {
  let path = router.location.pathname;
  [path] = path.split('?');
  return path.split('#')[0];
}


export function getTrack(router) {
  const path = getPath(router);
  let retVal;
  if (path) {
    forEach(tracks, (track) => {
      forEach(track.pages, (route) => {
        if (route.path === path) {
          retVal = track;
        }
      });
    });
  }
  return retVal;
}

export function getPathIndex(router) {
  let index = -1;
  const path = getPath(router);
  const track = getTrack(router);
  if (track) {
    forEach(track.pages, (route, idx) => {
      if (route.path === path) {
        index = idx;
      }
    });
  }
  return index;
}

function clearRailroad() {
  remainingRailroadTracks = [];
  initialRoadroadTracks = [];
}

export function setTrack(track, router, query = {}, options = {}) {
  if (!options.retainRailroad) {
    // Clear the railroad unless explicitly asked not too
    clearRailroad();
  }

  if (tracks[track]) {
    if (track === 'notification-VITAL-CORE-RR' || track === 'notification-IVC-RR-CLINICAL-STUDY') createVitalsRunnerWebWorker();
    router.push({ pathname: tracks[track].pages[0].path, query, state: { ...options } });
  } else {
    window.location.replace(`${config.PATIENT_APP_URL}/notifications/${query.notification_id}?dob=${query.redirect}`);
    console.error('Track Not Found: ', track);
  }
}

export function setRailroad(railroad, router, options = {}) {
  if (options.isInitial) {
    initialRoadroadTracks = railroad;
  }
  remainingRailroadTracks = railroad.slice(1);
  setTrack(railroad[0].track, router, railroad[0].query, { retainRailroad: true });
}

export function forward(router, query = {}) {
  const track = getTrack(router);
  const qs = Object.keys(query).length ? `?${queryString.stringify(query)}` : router.location.search;

  if (track) {
    const index = getPathIndex(router);
    if ((track.pages.length - 1) > index) {
      return router.push(track.pages[index + 1].path + qs);
    }

    // If no pages but a railroad exists...
    if (remainingRailroadTracks && remainingRailroadTracks.length) {
      return setRailroad(remainingRailroadTracks, router);
    }

    clearRailroad();
    return router.push(track.returnPath + qs);
  }
  router.push('/');
}

export function skipNext(router) {
  const track = getTrack(router);
  const qs = router.location.search;

  if (track) {
    const index = getPathIndex(router);
    if ((track.pages.length - 1) > (index + 1)) {
      return router.push(track.pages[index + 2].path);
    }
    return router.push(track.returnPath + qs);
  }
  router.push('/');
}

export function finishTrack(router) {
  const track = getTrack(router);
  const qs = router.location.search;
  if (remainingRailroadTracks.length) {
    return setRailroad(remainingRailroadTracks, router);
  }
  if (track && track.returnPath) {
    return router.push(track.returnPath + qs);
  }
  router.push('/');
}

export function backward(router, query = {}) {
  const track = getTrack(router);
  const qs = `?${queryString.stringify(query)}`;

  if (track) {
    const index = getPathIndex(router);
    if (index < 0) {
      return router.push(track.pages[index - 1].path + qs);
    }
    clearRailroad();
    return router.push(track.returnPath + qs);
  }
  router.push('/');
}

export function clear(router) {
  router.push('/');
}

// Returns the total number of pages in either the track
// Or the railroad if a railroad exists
export function getTotalPages(router) {
  if (initialRoadroadTracks.length) {
    let total = 0;

    initialRoadroadTracks.forEach(({ track }) => {
      if (tracks[track]) total += tracks[track].pages.length;
    });

    return total;
  }

  // no railroad exists, just get track total
  const track = getTrack(router);
  if (!track) {
    return 0;
  }
  return track.pages.length;
}

// Returns the current page number of either the track or
// the railroad
export function getCurrentPage(router) {
  let startingPage = 0;
  if (initialRoadroadTracks.length) {
    // adds total of any completed tracks to starting page
    const completedTracks = initialRoadroadTracks.filter((i) => {
      const isCompleted = (!remainingRailroadTracks.includes(i) && (tracks[i.track] !== getTrack(router)));
      return isCompleted;
    });
    completedTracks.forEach(({ track }) => {
      startingPage += tracks[track].pages.length;
    });
  }
  const index = getPathIndex(router);

  return index + 1 + startingPage;
}

export function goToIndex(index, router, query = {}) {
  const track = getTrack(router);
  const qs = Object.keys(query).length ? `?${queryString.stringify(query)}` : router.location.search;

  if (track) {
    return router.push(track.pages[index].path + qs);
  }

  router.push('/');
}
