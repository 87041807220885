import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import MobileDetect from 'mobile-detect';
import {
  CircularProgress,
  IconButton,
  LinearProgress,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';
import NoSleep from 'nosleep.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { pick } from 'lodash';

import AppBar from '../components/app-bar';
import Page from './page';
import HiddenContent from '../components/hidden-content';
import { updatePRO } from '../state/pro-forms';
import { startVideo } from '../lib/createAnswer';
import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';
import startVitalsRunner, {
  addCallbacksToWebWorker,
  isWebWorkerReady,
  loadDetector,
  preparationVideo,
  resetVitalsRunnerOnUnmount,
  setGuideBoxColor,
  stopPreparationVideo,
  toggleDevMode,
  startSessionJS,
} from '../lib/vitals-runner-rr';
import { throttledReset } from '../initializers/activity';
import { logout } from '../state/user';
import { CameraWithError, ClockWithError, NoInternet } from '../lib/icons';
import QuestionLayout from '../layouts/question';
import { setErrorScreenData } from '../state/error-screens';
import { vcDataStatusMessageMap, vcProcessDataStatus } from '../lib/vital-core';

const processingTimeoutLength = 300000;
const dataStatusWarningGracePeriod = 1000; // used to at the very beginning of data capture for the specified duration

const styles = {
  errorIconInnerWrapper: {
    width: 100,
  },
  errorIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 25,
    marginBottom: 25,
  },
  exclamationWrapper: {
    marginTop: '10rem',
    marginBottom: '8rem',
    '& path': {
      fill: colors.errorRed,
    },
    textAlign: 'center',
  },
  exclamationWrapperSmall: {
    display: 'inline-block',
    marginRight: 10,
    width: 25,
    '& path': {
      fill: 'white',
    },
  },
  errorHeader: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  linearProgressForPro: {
    background: colors.lightBrightBlue,
  },
  orderedList: {
    marginTop: 0,
    paddingLeft: 25,
  },
  patientVideo: {
    minHeight: '450px',
    height: '100%',
    maxWidth: 1000,
    objectFit: 'cover',
    transform: 'scaleX(-1)',
    width: '100%',
    display: 'none',
  },
  loadingModel: {
    fontSize: fontSizing.body,
    position: 'relative',
  },
  container: {
    backgroundColor: colors.questionBackground,
    height: '100%',
    position: 'relative',
  },
  topSection: {
    backgroundColor: colors.questionBackground,
    height: '100%',
    position: 'relative',
  },
  patientCanvas: {
    display: 'none',
    transform: 'scaleX(-1)',
    width: '100%',
  },
  patientCanvasVisible: {
    minHeight: '450px',
    height: '100%',
    // maxWidth: 1000,
    objectFit: 'cover',
    transform: 'scaleX(-1)',
    width: '100%',
  },
  videoContainer: {
    // margin: '0px auto',
    height: '100%',
    // maxWidth: '540px',
    width: '100%',
  },
  imReadyButton: {
    background: colors.primaryColor,
    borderRadius: 5,
    borderWidth: 0,
    color: 'white',
    flexGrow: 1,
    fontSize: fontSizing.body,
    maxWidth: 450,
    padding: 10,
    '&:disabled': {
      background: '#d9d8d8',
    },
  },
  imReadyButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: 20,
  },
  nonVideoContentContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  dialogText: {
    fontSize: fontSizing.body,
  },
  vitalCoreErrorContainer: {
    background: colors.errorRed,
    color: 'white',
    fontSize: fontSizing.body,
    padding: 10,
  },
  vitalCoreErrorText: {
    textAlign: 'center',
  },
  vitalCoreErrorRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  linearProgress: {
    background: 'lightgrey',
    height: 40,
  },
  linearProgressText: {
    color: 'white',
    left: 10,
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    zIndex: 2,
  },
  processingSection: {
    fontSize: fontSizing.body,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 60,
    textAlign: 'center',
  },
  circularProgressContainer: {
    alignItems: 'center',
    backgroundColor: colors.questionBackground,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 40,
    position: 'relative',
  },
  circularProgressText: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  circularProgressTextPercent: {
    fontSize: fontSizing.smallXX,
  },
  circularProgressWrapper: {
    display: 'inline-flex',
  },
  errorSection: {
    fontSize: fontSizing.body,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
    padding: 10,
    '& a': {
      fontSize: fontSizing.body,
    },
  },
  header: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1,
  },
  retryButton: {
    backgroundColor: colors.primaryColor,
    borderRadius: '0.3125rem',
    minWidth: '17.1875rem',
    color: colors.white,
    marginBottom: '0.625rem',
    marginTop: 40,
    width: '100%',
    minHeight: '4rem',
    fontSize: fontSizing.body,
    '@media (hover: none)': {
      '&:hover': {
        background: colors.primaryColor,
        color: colors.white,
      },
    },
  },
  menuButtonOuterWrapper: {
    display: 'flex',
    justifyContent: 'end',
  },
  menuButtonWrapper: {
    background: 'rgba(211,211,211,0.25)',
    borderBottomLeftRadius: 5,
    display: 'flex',
    justifyContent: 'end',
    padding: 10,
  },
  vitalsIconBlue: {
    color: '#1e7bcd',
  },
  vitalsIconRed: {
    color: '#cd3636',
  },
  icon: {
    width: 25,
    height: 25,
    position: 'absolute',
  },
  iconWrapper: {
    alignItems: 'center',
    background: '#fff',
    borderRadius: '50%',
    display: 'flex',
    height: 62,
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
    top: 0,
    width: 62,
    zIndex: 2,
  },
  iconBackground: {
    alignItems: 'center',
    background: '#fff',
    borderRadius: '50%',
    display: 'flex',
    height: 58,
    justifyContent: 'center',
    width: 58,
    zIndex: 1,
    position: 'absolute',
    top: 2,
    left: 2,
  },
  vitalDisplayWrapper: {
    position: 'relative',
    width: 62,
    height: 62,
  },
  vitalsRow: {
    display: 'flex',
    justifyContent: 'space-around',
    position: 'absolute',
    left: 0,
    top: '10%',
    right: 0,
  },
  iconCircularProgress: {
    position: 'absolute',
    zIndex: 3,
  },
  iconCircularProgressBackgroundRed: {
    color: '#eaafae',
    zIndex: 2,
  },
  iconCircularProgressBackgroundBlue: {
    color: '#a5caeb',
    zIndex: 2,
  },
  leftIcon: {
    transform: 'translateX(-20px)',
  },
  rightIcon: {
    transform: 'translateX(20px)',
  },
};

class VitalsCoreRR8 extends Page {
  constructor(props) {
    super(props);
    this.state = {
      cameraState: '',
      cameraError: '',
      me: {}, // todo unset in code
      stream: undefined,
      // lightingScore: , todo initial?
      // motionScore: , todo initial?
      signalPercent: 0,
      hrSignalPercent: 0,
      workerPct: 0,
      workerReady: false,
      md: null,
      HR: null,
      errorDialogOpen: false,
      timeLeft: 0,
      percentLeft: 1,
      processing: false,
      cachedFrameCount: 0,
      processedFrameCount: 0,
      vr: null, // todo unset in code
      latestTouchTap: {
        time: 0,
        target: null,
      },
      startedVitalsMeasurements: false,
      patientVideoDataLoaded: false,
      showImReady: true,
      coreWarnings: [],
      signsMsgs: [],
      patientCanvasVisibleHeight: null,
      patientCanvasVisibleWidth: null,
      startButtonEnabled: true,
    };

    this.patientCanvas = React.createRef();
    this.patientCanvasVisible = React.createRef();
    this.patientVideo = React.createRef();
    this.videoContainer = React.createRef();
    this.vrOnendCalled = React.createRef();
    this.vrOnendCalled.current = false;
    this.firstSignTimestamp = React.createRef();

    this.patientCanvasVisibleRAF = null;

    this.moduleDownloadingTimer = null;
    this.processingTimer = null;
    this.noSleep = null;

    addCallbacksToWebWorker({
      vrOnsigns: this.vrOnsigns,
      vrOnprocessing: this.vrOnprocessing,
      vrOnend: this.vrOnend,
      vrOntimeLeft: this.vrOntimeLeft,
    });

    this.reloadingVitalsRunner = false;
    this.startWorkerRAF = null;
    this.startWorkerRAFInitial = null;
  }

  componentDidMount = async () => {
    const md = new MobileDetect(window.navigator.userAgent);
    this.setState({ md });

    await this.startPreparationVideo();
    this.initiateVitalsRunner();
  }

  componentWillUnmount = () => {
    this.endCall();
    stopPreparationVideo();
    window.cancelAnimationFrame(this.patientCanvasVisibleRAF);
    window.cancelAnimationFrame(this.startWorkerRAF);
    if (this.noSleep) this.noSleep.disable();
    const videoElement = this.patientVideo.current;
    if (videoElement && videoElement.srcObject) {
      videoElement.srcObject.getTracks().forEach(t => t.stop());
      videoElement.srcObject = null;
    }
    if (this.state.vr) {
      this.state.vr.onend = null;
    }
    if (!this.reloadingVitalsRunner && this.state.workerReady) {
      // only reset vitals runner state when core is not getting reloaded,
      // and when web worker has been initialized
      resetVitalsRunnerOnUnmount();
    }
  }

  setDevMode = (e) => {
    // mobile workaround for double clicks
    const touchTap = {
      time: Date.now(),
      target: e.currentTarget,
    };
    const { latestTouchTap: ltt } = this.state;
    if (ltt && (ltt.target === touchTap.target) && (touchTap.time - ltt.time < 300)) {
      toggleDevMode();
    }
    this.setState({ latestTouchTap: touchTap });
  };

  endCall = () => {
    try {
      clearInterval(this.heartbeatInterval);
      clearInterval(this.activityTimer);
      clearTimeout(this.processingTimer);
    } catch (e) {
      console.error('error ending call properly: ', e);
    }
  }

  startPreparationVideo = () => {
    const { me } = this.state;

    return new Promise(async (resolve) => {
      try {
        // newest version or vital core doesn't seem to work in landscape mode
        const stream = await startVideo(me, {
          audio: false,
        });
        const patientVideo = this.patientVideo.current;

        patientVideo.addEventListener('loadeddata', async () => {
          loadDetector();
          const patientCanvasVisible = this.patientCanvasVisible.current;
          await preparationVideo(patientVideo, patientCanvasVisible);
          this.setState({ patientVideoDataLoaded: true });
          resolve();
        });

        patientVideo.srcObject = me.stream;
        try {
          await patientVideo.play();
        } catch (err) {
          console.error('error starting video', err);
        }

        this.setState({
          patientCanvasVisibleHeight: patientVideo.videoHeight,
          patientCanvasVisibleWidth: patientVideo.videoWidth,
          stream,
        });
      } catch (err) {
        this.setState({ cameraError: err, errorDialogOpen: true });
      }
    });
  };

  addWarning = (warningCode) => {
    const { coreWarnings } = this.state;

    const warningCodeMessage = vcDataStatusMessageMap[warningCode];

    if (coreWarnings.findIndex(warning => warning.code === warningCode) === -1) {
      this.setState((prevState) => {
        const coreWarningObj = {
          code: warningCode,
          message: warningCodeMessage,
        };
        return { coreWarnings: [...prevState.coreWarnings, coreWarningObj] };
      });
      setGuideBoxColor('red');

      setTimeout(() => {
        this.setState((prevState) => {
          const filteredWarnings = prevState.coreWarnings.filter(curWarning => curWarning.code !== warningCode);

          if (!filteredWarnings.length) {
            setGuideBoxColor('green');
          }

          return { coreWarnings: filteredWarnings };
        });
      }, 3000);
    }
  };

  vrOnVitalCoreVersion = (ivcVersion) => {
    const { vitalCoreRR } = this.props;

    this.props.updatePRO({
      type: 'vitalCoreRR',
      position: this.getTrackIndex(),
      value: {
        ...vitalCoreRR[this.getTrackIndex()],
        ivcVersion,
      },
    });
  };

  vrOndownloadingPct = (data) => {
    this.setState({ workerPct: data.pct });
  };

  vrOntimeLeft = (timeLeft, percentLeft) => {
    this.setState({ timeLeft, percentLeft });
  };

  vrOnprocessing = (processing) => {
    if (processing) {
      this.processingTimer = setTimeout(() => {
        window.localStorage.removeItem('vitalCoreVisitedTerms');
        window.localStorage.removeItem('vitalCoreVisitedConsentToRecord');
        window.localStorage.removeItem('vitalCoreVisitedInstructions');

        const handleRetry = () => {
          const { pathname, search } = this.props.location;
          this.props.router.push(`${pathname}${search}`);
        };

        this.props.setErrorScreenData({
          header: 'Timed Out',
          messageOne: 'Your session has timed out and we were unable to analyze your vitals',
          messageTwo: <>Please tap <strong>RETRY.</strong></>,
          notificationAuthLogoutRoute: this.props.notificationAuthLogoutRoute,
          pageTitle: 'Analyzing Results',
          icon: <ClockWithError />,
          onRetry: handleRetry,
          noLogout: true,
        });

        this.props.router.push('/timeout');
      }, processingTimeoutLength);
    }

    const videoElement = this.patientVideo.current;
    if (videoElement && videoElement.srcObject) {
      videoElement.srcObject.getTracks().forEach(t => t.stop());
      videoElement.srcObject = null;
    }

    this.setState({ processing });
  };

  vrOnsigns = (data) => {
    const { HR: curHR, signsMsgs } = this.state;
    const { signs, cachedFrameCount, processedFrameCount } = data;
    signsMsgs.push(pick(signs, [
      'timestamp',
      'HR',
      'snrHR',
      'dataStatus',
      'BR',
      'faceHeight',
      'faceWidth',
      'faceX',
      'faceY',
      'lightingScore',
      'motionScore',
      'duplicateFrame',
    ]));
    const {
      signalPercent,
      hrSignalPercent,
    } = signs;
    let { dataStatus } = signs;
    let { HR } = signs;

    if (curHR > 0 && HR < 1) HR = curHR;

    if (!this.firstSignTimestamp.current) {
      this.firstSignTimestamp.current = Date.now();
    }

    // warnings are suppressed at the very beginning of data capture for the duration specified by dataStatusWarningGracePeriod
    if ((Date.now() - dataStatusWarningGracePeriod) < this.firstSignTimestamp.current) {
      dataStatus = '';
    }

    const processedDataStatus = vcProcessDataStatus(dataStatus);

    if (processedDataStatus.action === 'warning') {
      processedDataStatus.data.forEach((dataStatusMessage) => { this.addWarning(dataStatusMessage); });
    }

    // Abruptly ending session is currently not supported
    // } else if (processedDataStatus.action === 'restart' || processedDataStatus.action === 'reload') {
    //   let {
    //     criticalErrorCount,
    //     sessionCount,
    //     vitalsMeasurements: prevVitalsMeasurements,
    //   } = this.getDataMap(this.props.vitalCoreRR);

    //   prevVitalsMeasurements = prevVitalsMeasurements || [];
    //   criticalErrorCount = criticalErrorCount || 0;
    //   sessionCount = sessionCount || 0;

    //   this.props.updatePRO({
    //     type: 'vitalCoreRR',
    //     position: this.getTrackIndex(),
    //     value: {
    //       ...vitalCoreRR[this.getTrackIndex()],
    //       vitalsMeasurements: [
    //         signs,
    //         ...prevVitalsMeasurements,
    //       ],
    //       criticalErrorCount: criticalErrorCount + 1,
    //       sessionCount: sessionCount + 1,
    //       shouldRestart: true,
    //       errorCode: processedDataStatus.data,
    //       signsMsgs,
    //     },
    //   });

    //   this.setState({ signsMsgs: [] });

    //   if (processedDataStatus.action === 'reload') {
    //     this.reloadingVitalsRunner = true;
    //     killWebWorker();
    //     setVitalsRunnerStateForReload();
    //     createVitalsRunnerWebWorker();
    //   }

    //   this.forwardWithQuery(this.props.location.query);
    // }

    this.setState({
      HR,
      signalPercent,
      hrSignalPercent,
      cachedFrameCount,
      processedFrameCount,
    });
  };

  vrOnend = (lastSigns) => {
    if (this.vrOnendCalled.current === false) {
      this.vrOnendCalled.current = true;

      const { BR, HR } = lastSigns;
      const {
        currentVitalsMeasurementValues: { BR: prevBR, HR: prevHR },
        ivcVersion,
      } = this.getDataMap(this.props.vitalCoreRR);
      let {
        sessionCount,
        vitalsMeasurements: prevVitalsMeasurements,
        signsMsgs: prevSignsMsgs,
        brOutOfRangeCount,
        hrOutOfRangeCount,
      } = this.getDataMap(this.props.vitalCoreRR);

      prevVitalsMeasurements = prevVitalsMeasurements || [];
      prevSignsMsgs = prevSignsMsgs || [];
      sessionCount = sessionCount || 0;

      const brOutOfRange = BR > 0 && (BR < 10 || BR > 22);
      const hrOutOfRange = HR > 0 && (HR < 40 || HR > 120);

      const currentHR = HR > 0 ? HR : prevHR;
      const currentBR = BR > 0 ? BR : prevBR;
      const needHR = currentHR <= 0 || hrOutOfRange;
      const needBR = currentBR <= 0 || brOutOfRange;

      if (brOutOfRange && brOutOfRangeCount < 2) brOutOfRangeCount++;
      if (hrOutOfRange && hrOutOfRangeCount < 2) hrOutOfRangeCount++;

      const value = {
        vitalsMeasurements: [
          lastSigns,
          ...prevVitalsMeasurements,
        ],
        currentVitalsMeasurementValues: {
          HR: currentHR,
          BR: currentBR,
          needHR,
          needBR,
        },
        brOutOfRangeCount,
        hrOutOfRangeCount,
        sessionCount: sessionCount + 1,
        signsMsgs: [
          this.state.signsMsgs,
          ...prevSignsMsgs,
        ],
        ivcVersion,
      };

      const processedDataStatus = vcProcessDataStatus(lastSigns.dataStatus, true);
      if (processedDataStatus.action === 'restart' || processedDataStatus.action === 'restart') {
        value.shouldRestart = true;
        value.errorCode = processedDataStatus.data;
      }

      this.props.updatePRO({
        type: 'vitalCoreRR',
        position: this.getTrackIndex(),
        value,
      });
      this.forwardWithQuery(this.props.location.query);
    }
  };

  initiateVitalsRunner = () => {
    try {
      const patientVideo = this.patientVideo.current;
      const canvas = this.patientCanvas.current;
      const patientCanvasVisible = this.patientCanvasVisible.current;

      this.moduleDownloadingTimer = setTimeout(() => {
        window.localStorage.removeItem('vitalCoreVisitedTerms');
        window.localStorage.removeItem('vitalCoreVisitedConsentToRecord');
        window.localStorage.removeItem('vitalCoreVisitedInstructions');

        if (!navigator.onLine) {
          this.props.setErrorScreenData({
            header: 'Lost Internet Connection',
            messageOne: <i>Unable to download the IVC App.</i>,
            messageTwo: <>Please try again later when you have access to the Internet and tap <strong>RETRY.</strong></>,
            notificationAuthLogoutRoute: this.props.notificationAuthLogoutRoute,
            pageTitle: 'No Internet',
            icon: <NoInternet />,
          });
          this.props.router.push('/timeout');
          return;
        }

        this.props.updatePRO({
          type: 'vitalCoreRR',
          position: this.getTrackIndex(),
          value: {
            ...this.props.vitalCoreRR[this.getTrackIndex()],
            currentVitalsMeasurementValues: {
              BR: -1,
              HR: -1,
            },
            deviceError: true,
          },
        });

        this.forwardWithQuery(this.props.location.query);
      }, processingTimeoutLength);

      this.noSleep = new NoSleep();
      this.noSleep.enable();

      const startWhenWorkerIsReady = () => {
        const workerIsReady = isWebWorkerReady();
        if (workerIsReady) {
          this.setState({ workerReady: true });
          clearTimeout(this.moduleDownloadingTimer);
          startVitalsRunner(patientVideo, canvas, patientCanvasVisible, {
            vrOnprocessing: this.vrOnprocessing,
            vrOntimeLeft: this.vrOntimeLeft,
            vrOnend: this.vrOnend,
            vrGetCoreWarnings: this.vrGetCoreWarnings,
            vrOnVitalCoreVersion: this.vrOnVitalCoreVersion,
          });
          window.cancelAnimationFrame(this.startWorkerRAF);
        } else {
          this.startWorkerRAF = window.requestAnimationFrame(startWhenWorkerIsReady);
        }
      };

      this.startWorkerRAF = window.requestAnimationFrame(startWhenWorkerIsReady);
    } catch (err) {
      this.setState({ cameraError: err, errorDialogOpen: true });
    }
  }

  startCamera = () => {
    const { currentVitalsMeasurementValues: { needHR, needBR } } = this.getDataMap(this.props.vitalCoreRR);
    this.firstSignTimestamp.current = null;

    startSessionJS(
      {
        vrOnprocessing: this.vrOnprocessing,
        vrOntimeLeft: this.vrOntimeLeft,
      },
      needHR,
      needBR,
      false,
      false,
    );

    this.activityTimer = setInterval(throttledReset, 10000);

    this.setState({
      cameraState: 'started',
      showImReady: false,
      startedVitalsMeasurements: true,
    });
  }

  vrGetCoreWarnings = () => {
    return this.state.coreWarnings;
  }

  render() {
    const { classes, router } = this.props;
    const {
      errorDialogOpen,
      patientVideoDataLoaded,
      processing,
      showImReady,
      coreWarnings,
      patientCanvasVisibleHeight,
      patientCanvasVisibleWidth,
      startedVitalsMeasurements,
    } = this.state;
    const videoContainerStyle = { display: this.state.cameraDenied ? 'none' : '' };

    // some dataStatus codes have the same message. prevent displaying redundant messages
    const coreWarningsSet = new Set();
    coreWarnings.forEach((coreWarning) => {
      coreWarningsSet.add(coreWarning.message);
    });
    const coreWarningsFiltered = Array.from(coreWarningsSet);

    // disable start button if a warning message is displayed on the screen
    const disableStartButton = !!coreWarningsFiltered.length;

    return (
      <div className={classes.container}>
        {!processing ? (
          <div className={classes.header}>
            {coreWarningsFiltered.length ? (
              <div className={classes.vitalCoreErrorContainer}>
                {coreWarningsFiltered.map(vitalCoreError => (
                  <div className={classes.vitalCoreErrorRow} key={vitalCoreError}>
                    <div className={classes.exclamationWrapperSmall}>
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    </div>
                    <div className={classes.vitalCoreErrorText}>{vitalCoreError}</div>
                  </div>
                ))}
              </div>
            ) : null}
            <div className={classes.menuButtonOuterWrapper}>
              <div className={classes.menuButtonWrapper}>
                <IconButton onClick={() => router.push('/core-vital-user-manual-menu')}>
                  <MenuIcon fontSize="large" />
                </IconButton>
              </div>
            </div>
          </div>
        ) : null}
        {processing && !errorDialogOpen ? (
          <div className={classes.processingSection}>
            <div className={classes.circularProgressContainer}>
              <div className={classes.circularProgressWrapper}>
                <CircularProgress
                  value={Math.round((this.state.processedFrameCount / this.state.cachedFrameCount) * 100)}
                  variant="determinate"
                  size={100}
                />
              </div>
              <div className={classes.circularProgressText}>
                <div>
                  <span>{Math.round((this.state.processedFrameCount / this.state.cachedFrameCount) * 100)}</span>
                  <span className={classes.circularProgressTextPercent}>%</span>
                </div>
              </div>
            </div>
            <p><strong>Analyzing...</strong></p>
            <p>Please sit tight while we analyze your video.</p>
            <p>This should only take a minute or two.</p>
          </div>
        ) : null}
        {!processing && !errorDialogOpen ? (
          <section className={classes.topSection}>
            <div
              id="videoContainer"
              className={classes.videoContainer}
              style={videoContainerStyle}
              ref={this.videoContainer}
            >
              <video id="patientVideo" ref={this.patientVideo} playsInline autoPlay className={classes.patientVideo} muted />
              <canvas id="patientCanvas" ref={this.patientCanvas} className={classes.patientCanvas} />
              <canvas
                id="patientCanvasVisible"
                ref={this.patientCanvasVisible}
                className={classes.patientCanvasVisible}
                height={patientCanvasVisibleHeight}
                width={patientCanvasVisibleWidth}
              />
            </div>
            <div className={classes.nonVideoContentContainer}>
              <HiddenContent hidden={!showImReady || !this.state.workerReady}>
                <div className={classes.imReadyButtonWrapper}>
                  <button
                    className={classes.imReadyButton}
                    onClick={this.startCamera}
                    type="button"
                    disabled={!patientVideoDataLoaded || disableStartButton}
                  >
                    START
                  </button>
                </div>
              </HiddenContent>
              <HiddenContent hidden={this.state.workerReady}>
                <div className={classes.loadingModel}>
                  <span className={classes.linearProgressText}>Loading</span>
                  <LinearProgress
                    className={classes.linearProgress}
                    value={Math.round(this.state.workerPct * 100)}
                    variant="indeterminate"
                  />
                </div>
              </HiddenContent>
              <HiddenContent hidden={(!this.state.workerReady || !startedVitalsMeasurements)}>
                <div className={classes.loadingModel} onClick={this.setDevMode}>
                  <span className={classes.linearProgressText}>Collecting</span>
                  <LinearProgress
                    className={classes.linearProgress}
                    value={Math.round((1 - this.state.percentLeft) * 100)}
                    variant="determinate"
                  />
                </div>
              </HiddenContent>
            </div>
          </section>
        ) : null}
        {errorDialogOpen ? (
          <QuestionLayout
            style={{ backgroundColor: colors.questionBackground }}
          >
            <AppBar
              backButtonOnClick={() => this.props.router.goBack()}
              headerNode="Camera Permissions"
              rightNode={(
                <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
                  <MenuIcon fontSize="large" />
                </IconButton>
              )}
            />
            <LinearProgress
              variant="determinate"
              value={(this.getCurrentPage() / this.getTotalPages()) * 100}
              classes={{ bar: classes.linearProgressForPro }}
            />
            <div className={classes.errorSection}>
              <div className={classes.errorIconWrapper}>
                <div className={classes.errorIconInnerWrapper}>
                  <CameraWithError />
                </div>
              </div>
              <p className={classes.errorHeader}>Camera Denied</p>
              <p>
                <i>
                  Unable to take your vitals. This web-app needs permission to use your camera to measure your vitals.
                </i>
              </p>
              <div><strong>Instructions:</strong></div>
              <ol className={classes.orderedList}>
                <li>Enable camera permissions in your browser</li>
                <li>Return to your message app</li>
                <li>Press the link in the text message</li>
              </ol>
              <p>Please <strong>close this browser</strong> tab.</p>
            </div>
          </QuestionLayout>
        ) : null}
      </div>
    );
  }
}

VitalsCoreRR8.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    proForms: { vitalCoreRR },
    user: { notificationAuthLogoutRoute },
  } = state;

  return { notificationAuthLogoutRoute, vitalCoreRR };
};

export default connect(mapStateToProps, { logout, setErrorScreenData, updatePRO })(withStyles(styles)(VitalsCoreRR8));
