import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory } from 'react-router';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Switch from '@material-ui/core/Switch';
import MenuIcon from '@material-ui/icons/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';

import AppBar from '../components/app-bar';
import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';
import { saveSkipVitalCoreInstructions } from '../state/user';
import IvcVersionInfo from '../components/ivc-version-info';

const styles = {
  arrowIcons: {
    verticalAlign: 'bottom',
  },
  bodyText: {
    fontSize: fontSizing.body,
    marginTop: 20,
  },
  cautionStatementWrapper: {
    marginBottom: 10,
    marginTop: 20,
  },
  checkmark: {
    width: '24.7rem',
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  header: {
    fontSize: fontSizing.h3,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 5,
  },
  highlightRed: {
    color: colors.errorRed,
  },
  icon: {
    margin: '20px auto',
    width: '4.5rem',
    height: '4.5rem',
  },
  imgContainer: {
    display: 'flex',
    marginBottom: '30px',
  },
  img: {
    width: '11.6rem',
    height: '20.6rem',
    objectFit: 'cover',
    borderRadius: '5px',
  },
  imgLeft: {
    width: '11.6rem',
    height: '20.6rem',
    objectFit: 'cover',
    borderRadius: '5px',
    marginRight: '1.5rem',
  },
  imgLg: {
    width: '24.7rem',
    height: '44rem',
    objectFit: 'cover',
    borderRadius: '5px',
    marginBottom: '30px',
  },
  imgSm: {
    width: '11.6rem',
    height: '14.5rem',
    objectFit: 'cover',
    borderRadius: '5px',
  },
  imgSmLeft: {
    width: '11.6rem',
    height: '14.5rem',
    objectFit: 'cover',
    borderRadius: '5px',
    marginRight: '1.5rem',
  },
  instructionIcon: {
    marginBottom: 10,
    width: 50,
  },
  instructionImage: {
    margin: 10,
    width: 245,
  },
  instructionImages: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  instructionImageContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maringBottom: 20,
    marginTop: 20,
  },
  li: {
    display: 'table',
    '&:before': {
      content: '-',
      display: 'table-cell',
      paddingRight: '0.6em',
    },
  },
  list: {
    width: '100%',
  },
  noLineWrap: {
    whiteSpace: 'nowrap',
  },
  pageContent: {
    fontSize: fontSizing.body,
    marginTop: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
    paddingLeft: 10,
    paddingRight: 10,
  },
  pageWrapper: {
    background: colors.white,
    minHeight: '100vh',
    paddingBottom: 20,
  },
  redText: {
    color: colors.errorRed,
  },
  subheader: {
    fontSize: fontSizing.small,
    fontWeight: 'normal',
    marginTop: 5,
  },
  subText: {
    fontSize: fontSizing.small,
  },
  text: {
    fontSize: fontSizing.body,
    marginTop: 20,
  },
  ul: {
    listStyleType: "'- '",
    margin: 0,
  },
  boldItalics: {
    fontStyle: 'italic',
    fontWeight: 'bold',
    marginBottom: 20,
  },
};

class CoreVitalHowToUse extends React.Component {
  handleChangeSkipInstructions = (e) => {
    const { saveSkipVitalCoreInstructions, user } = this.props;
    saveSkipVitalCoreInstructions(!e.target.checked, user.id);
  };

  render() {
    const { classes, router, user } = this.props;

    return (
      <div className={classes.pageWrapper}>
        <AppBar
          backButtonOnClick={() => router.goBack()}
          headerNode="User Manual"
          rightNode={(
            <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
              <MenuIcon fontSize="large" />
            </IconButton>
          )}
        />
        <div className={classes.pageContent}>
          <div>
            <div className={classes.header}>How to use Informed Vital Core:</div>
            <div className={classes.cautionStatementWrapper}>
              <IvcVersionInfo />
            </div>
          </div>
          <Divider />
          <List component="nav" className={classes.list} aria-label="mailbox folders">
            <ListItem sx={{ height: '60px' }}>
              <ListItemText primary={<strong style={{ fontSize: fontSizing.body }}>Show Instructions</strong>} />
              <ListItemSecondaryAction>
                <FormControlLabel
                  control={(
                    <Switch
                      name="showInstructions"
                      color="primary"
                      checked={!user.skip_vital_core_instructions}
                      onChange={this.handleChangeSkipInstructions}
                    />
                    )}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider sx={{ width: '100%' }} />
          </List>
          <div className={classes.header}>
            How to use Informed Vital Core:
          </div>
          <p className={classes.bodyText}>
            Informed Vital Core must be used indoors in typical home lighting conditions.
          </p>
          <p className={classes.bodyText}>
            Make sure there is nothing behind you while the device is in use (examples: TV in the background, pets, or people standing or walking behind you).
          </p>
          <div><strong>Instructions:</strong></div>
          <ol>
            <li>
              <div className={classes.bodyText}>
                <div>Place your device on a stand and on a firm, stable surface so that it is not moving while measuring your vitals. If you do not have a stand, place your device up against something with a solid backing so that it doesn’t move.</div>
              </div>
              <div className={classes.instructionImageContainer}>
                <img
                  src="/img/vitals-1.jpg"
                  alt="person smiling"
                  className={classes.instructionImage}
                />
              </div>
            </li>
            <li>
              <div className={classes.bodyText}>
                <div>Find a place with good lighting so your face is well and evenly lit. No shadows or bright spots should be on your face.</div>
              </div>
              <div className={classes.instructionImageContainer}>
                <img
                  src="/img/rr-poor-lighting.png"
                  alt="person and red guide box"
                  className={classes.instructionImage}
                />
              </div>
            </li>
            <li>
              <div className={classes.bodyText}>
                <div>Remove any hats, clothing, or anything that may cover your face or shoulders.  Pull the hair back off your neck and make sure both shoulders are visible.</div>
              </div>
              <div className={classes.instructionImageContainer}>
                <img
                  src="/img/rr-hair-pulled-back.jpg"
                  alt="person with hair pulled back"
                  className={classes.instructionImage}
                />
              </div>
            </li>
            <li>
              <div className={classes.bodyText}>
                We need to see your forehead, cheeks, and chin.  You can leave your glasses on. Remove makeup from your cheeks, forehead, and chin.
              </div>
            </li>
            <li>
              <div className={classes.bodyText}>
                <div>Position yourself in front of the device so that the onscreen guide stays green.  Look straight into the camera or at yourself on the screen. It will turn red if you are too close or too far from the screen, you move too much, or the lighting is poor.</div>
              </div>
              <div className={classes.instructionImageContainer}>
                <div className={classes.instructionImages}>
                  <img
                    src="/img/rr-in-frame.png"
                    alt="person and green guide box"
                    className={classes.instructionImage}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className={classes.bodyText}>
                Make sure that the color of your clothing doesn’t match your background. For example: White shirt against a white wall.
              </div>
            </li>
            <li>
              <div className={classes.bodyText}>
                Relax and sit comfortably in a chair for 5 minutes. Legs uncrossed, feet on the floor, back pressed lightly against the chair.
              </div>
            </li>
            <li>
              <div className={classes.bodyText}>
                <div>Stay still during vital measurements. Do not talk or move your head while your vitals are measured.</div>
              </div>
              <div className={classes.instructionImageContainer}>
                <div className={classes.instructionImages}>
                  <img
                    src="/img/rr-hold-phone-steady.png"
                    alt="person and hold still warning"
                    className={classes.instructionImage}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className={classes.bodyText}>
                <div>When the START button is activated, tap or click it to begin measuring your vitals.</div>
              </div>
              <div className={classes.instructionImageContainer}>
                <div className={classes.instructionImages}>
                  <img
                    src="/img/vitals-Start-Button.jpg"
                    alt="start button"
                    className={classes.instructionImage}
                  />
                </div>
              </div>
            </li>
          </ol>
          <div className={classes.boldItalics}>NOTE: The IVC App may not run on all devices or return a measurement every time. If the IVC App doesn’t return a measurement, use an alternative method to measure your vitals and inform your healthcare provider.</div>
          <div>
            {' Do not use the browser navigation buttons '}
            <span className={classes.noLineWrap}>(<FontAwesomeIcon icon={faAngleLeft} className={classes.arrowIcons} />  <FontAwesomeIcon icon={faAngleRight} className={classes.arrowIcons} />)</span>
            {' once you are on the video collection screen.'}
          </div>
        </div>
      </div>
    );
  }
}

CoreVitalHowToUse.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  saveSkipVitalCoreInstructions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state;

  return { user };
};

export default connect(mapStateToProps, { saveSkipVitalCoreInstructions })(withStyles(styles)(CoreVitalHowToUse));
