import React from 'react';

import VitalCore1 from './pages/vital-core-1';
import VitalCore2 from './pages/vital-core-2';
import VitalCore3 from './pages/vital-core-3';
import VitalCore4 from './pages/vital-core-4';
import VitalCore5 from './pages/vital-core-5';
import VitalCore6 from './pages/vital-core-6';
import VitalCore7 from './pages/vital-core-7';
import VitalCore8 from './pages/vital-core-8';
import VitalCore9 from './pages/vital-core-9';

import VitalCoreBenchHrInfo from './pages/vital-core-bench-hr-info';
import VitalCoreBenchHrBmi from './pages/vital-core-bench-hr-bmi';
import VitalCoreBenchHrMakeup from './pages/vital-core-bench-hr-makeup';
import VitalCoreBenchHrFitzpatrick from './pages/vital-core-bench-hr-fitzpatrick';
import VitalCoreBenchHrFacial from './pages/vital-core-bench-hr-facial';
import VitalCoreBenchHrPair from './pages/vital-core-bench-hr-pair';
import VitalCoreBenchHrPreview from './pages/vital-core-bench-hr-preview';
import VitalCoreBenchHr5 from './pages/vital-core-bench-hr-5';
import VitalCoreBenchHr6 from './pages/vital-core-bench-hr-6';
import VitalCoreBenchHr7 from './pages/vital-core-bench-hr-7';
import VitalCoreBenchHr8 from './pages/vital-core-bench-hr-8';
import VitalCoreBenchHr9 from './pages/vital-core-bench-hr-9';

import IvcPrClinicalStudy1 from './pages/ivc-pr-clinical-study-1';
import IvcPrClinicalStudy2 from './pages/ivc-pr-clinical-study-2';
import IvcPrClinicalStudy3 from './pages/ivc-pr-clinical-study-3';
import IvcPrClinicalStudy4 from './pages/ivc-pr-clinical-study-4';

import VitalCoreRR1 from './pages/vital-core-rr-1';
import VitalCoreRR2 from './pages/vital-core-rr-2';
import VitalCoreRR3 from './pages/vital-core-rr-3';
import VitalCoreRR4 from './pages/vital-core-rr-4';
import VitalCoreRR5 from './pages/vital-core-rr-5';
import VitalCoreRR6 from './pages/vital-core-rr-6';
import VitalCoreRR7 from './pages/vital-core-rr-7';
import VitalCoreRR8 from './pages/vital-core-rr-8';
import VitalCoreRR9 from './pages/vital-core-rr-9';

import IvcRRClinicalStudy1 from './pages/ivc-rr-clinical-study-1';
import IvcRRClinicalStudy2 from './pages/ivc-rr-clinical-study-2';
import IvcRRClinicalStudy3 from './pages/ivc-rr-clinical-study-3';

const notificationTracks = [
  {
    type: 'BUNDLED_EVENT',
    introHeaderText: 'Patient Reported Outcomes',
    introBodyText: 'requests that you review several items',
    loggedInSuccessText: 'Successful Patient Report!',
    loggedOutSuccessText: 'Thank you for submitting your responses. You may now close this window.',
    components: [], // This one has some special handling as it just combined the above but the empty array is still needed
  },
  {
    type: 'VITAL-CORE',
    introHeaderText: 'Informed Vital Core',
    introBodyText: 'requests that you measure your vitals',
    loggedInSuccessText: (
      <div>
        <p><strong>Thank you.</strong></p>
        <p>Your results have been submitted to your healthcare provider.</p>
        <p>Please <strong>close this browser</strong> tab.</p>
      </div>
    ),
    loggedOutSuccessText: (
      <div>
        <p><strong>Thank you.</strong></p>
        <p>Your results have been submitted to your healthcare provider.</p>
        <p>Please <strong>close this browser</strong> tab.</p>
      </div>
    ),
    components: [
      VitalCore1,
      VitalCore2,
      VitalCore3,
      VitalCore4,
      VitalCore5,
      VitalCore6,
      VitalCore7,
      VitalCore8,
      VitalCore9,
    ],
  },
  {
    type: 'VITAL-CORE-BENCH-HR',
    introHeaderText: 'Informed Vital Core Benchmark HR',
    introBodyText: 'requests that you measure your vitals',
    loggedInSuccessText: (
      <div>
        <p><strong>Thank you.</strong></p>
        <p>Your results have been submitted to your healthcare provider.</p>
        <p>Please <strong>close this browser</strong> tab.</p>
      </div>
    ),
    loggedOutSuccessText: (
      <div>
        <p><strong>Thank you.</strong></p>
        <p>Your results have been submitted to your healthcare provider.</p>
        <p>Please <strong>close this browser</strong> tab.</p>
      </div>
    ),
    components: [
      VitalCoreBenchHrInfo,
      VitalCoreBenchHrBmi,
      VitalCoreBenchHrMakeup,
      VitalCoreBenchHrFitzpatrick,
      VitalCoreBenchHrFacial,
      VitalCoreBenchHrPair,
      VitalCoreBenchHr5,
      VitalCoreBenchHr6,
      VitalCoreBenchHr7,
      VitalCoreBenchHrPreview,
      VitalCoreBenchHr8,
      VitalCoreBenchHr9,
    ],
  },
  {
    type: 'IVC-PR-CLINICAL-STUDY',
    introHeaderText: 'Informed Vital Core',
    introBodyText: 'requests that you measure your vitals.',
    loggedInSuccessText: (
      <div>
        <p><strong>Thank you.</strong></p>
        <p>Your results have been submitted to your healthcare provider.</p>
        <p>Please <strong>close this browser</strong> tab.</p>
      </div>
    ),
    loggedOutSuccessText: (
      <div>
        <p><strong>Thank you.</strong></p>
        <p>Your results have been submitted to your healthcare provider.</p>
        <p>Please <strong>close this browser</strong> tab.</p>
      </div>
    ),
    components: [
      VitalCore5,
      VitalCore6,
      VitalCore7,
      IvcPrClinicalStudy1,
      IvcPrClinicalStudy2,
      IvcPrClinicalStudy3,
      IvcPrClinicalStudy4,
    ],
  },
  {
    type: 'IVC-RR-CLINICAL-STUDY',
    introHeaderText: 'Informed Vital Core',
    introBodyText: 'requests that you measure your vitals measurements.',
    loggedInSuccessText: (
      <div>
        <p>Thank you for submitting your vitals.</p>
        <p>Please <strong>close this browser</strong> tab to remove your vital sign results from your browser's memory.</p>
      </div>
    ),
    loggedOutSuccessText: (
      <div>
        <p>Thank you for submitting your vitals.</p>
        <p>Please <strong>close this browser</strong> tab to remove your vital sign results from your browser's memory.</p>
      </div>
    ),
    components: [
      IvcRRClinicalStudy1,
      IvcRRClinicalStudy2,
      IvcRRClinicalStudy3
    ],
  },
  {
    type: 'VITAL-CORE-RR',
    introHeaderText: 'Informed Vital Core',
    introBodyText: 'requests that you measure your respiratory rate.',
    loggedInSuccessText: (
      <div>
        <p><strong>Thank you.</strong></p>
        <p>Your results have been submitted to your healthcare provider.</p>
        <p>Please <strong>close this browser</strong> tab.</p>
      </div>
    ),
    loggedOutSuccessText: (
      <div>
        <p><strong>Thank you.</strong></p>
        <p>Your results have been submitted to your healthcare provider.</p>
        <p>Please <strong>close this browser</strong> tab.</p>
      </div>
    ),
    components: [
      VitalCoreRR1,
      VitalCoreRR2,
      VitalCoreRR3,
      VitalCoreRR4,
      VitalCoreRR5,
      VitalCoreRR6,
      VitalCoreRR7,
      VitalCoreRR8,
      VitalCoreRR9,
    ],
  },
];

export default notificationTracks;
